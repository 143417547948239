import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, createTheme, ThemeProvider, Grid, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import '../homeStyles.css';
import adobe from '../images/Adobe Experience Cloud logo.jpg';
import appsflyer from '../images/appsflyerimages.png';
import cm360 from '../images/Campaign Manager Logo.png';
import dv360 from '../images/DV360 SVG.png';
import ensighten from '../images/Ensighten logo.png';
import googleads from '../images/Google Ads icon.png';
import googleanalytics from '../images/Google Analytics icon.png';
import googlecloud from '../images/Google Cloud icon.png';
import gmp from '../images/Google Marketing Platform Icon.png';
import sa360 from '../images/Google Search Ads 360 Logo.png';
import gtm from '../images/Google Tag Manager icon.png';
import html5 from '../images/Html 5 icon.png';
import js from '../images/Javascript Vector Icon.png';
import meta from '../images/Meta 1.png';
import onetrust from '../images/OneTrust Logo Vector.jpg';
import python from '../images/Python icon.png';
import snapchat from '../images/Snapchat Logo.png';
import sql from '../images/Sql Database generic.png';
import tiktok from '../images/TikTok icon 2.png';
import looker from '../images/Looker Studio Logo.png';
import http from '../images/HTTP logo.png';
import Footer from './Footer';
import NowPlaying from "../components/NowPlaying";

const Technologies = () => {
  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  const images = [
    { src: adobe, title: "Adobe Experience Cloud", description: "Setup client side data pipeline to AEP using the WebSDK." },
    { src: appsflyer, title: "Appsflyer", description: "Integrate with all performance marketing partners, setup SKAN attribution and keep platform aligned with latest product updates." },
    { src: cm360, title: "Campaign Manager 360", description: "Deploy floodlights to app and web, alongside setup troubleshooting." },
    { src: dv360, title: "Display & Video 360", description: "Channel audiences from CDP via Google Cloud Platform" },
    { src: ensighten, title: "Ensighten", description: "Ongoing tag management maintenance and implementation of new analytics and marketing tracking." },
    { src: googleads, title: "Google Ads", description: "Google Ads Script creation, campaign troubleshooting and optimisation." },
    { src: googleanalytics, title: "Google Analytics", description: "Migration of tracking from UA to GA4 alongside general platform configuration and troubleshooting." },
    { src: googlecloud, title: "Google Cloud", description: "Setup and monitor different aspects of Google Cloud Platform including Transfer Jobs and Cloud Run." },
    { src: gmp, title: "Google Marketing Platform", description: "Deep understanding of the GMP ecosystem and the relationship between all the underlying platforms." },
    { src: sa360, title: "Search Ads 360", description: "SA360 API querying alongside campaign troubleshooting and configuration." },
    { src: gtm, title: "Google Tag Manager", description: "Platform expert across both client and server-side including setup, migrations and troubleshooting." },
    { src: html5, title: "HTML5", description: "Client side web tags and used within this website." },
    { src: js, title: "JavaScript", description: "Highly proficient within both client-side web and tracking contexts alongside general programming for example within Google App Scripts." },
    { src: meta, title: "Meta", description: "Implementation across Events Manager, Conversions API, product feeds and web tracking." },
    { src: onetrust, title: "OneTrust", description: "Platform expertise alongside deep understanding of how to intergate with tag management systems and Google Consent Mode." },
    { src: python, title: "Python", description: "Highly adept through university studies, projects during my career and ongoing Advanced Data Analytics Certification." },
    { src: snapchat, title: "Snapchat", description: "Implementation across Events Manager, Conversions API, catalogs and web tracking." },
    { src: sql, title: "SQL", description: "Experience querying databases and using Google BigQuery." },
    { src: tiktok, title: "TikTok", description: "Implementation across Events Manager setup, Events API, catalogs and web signals." },
    { src: looker, title: "Looker Studio", description: "Experience using Looker Studio as an end user and Data Studio configuration before the product merge." },
    { src: http, title: "HTTP", description: "Strong understanding of GET, POST and general API troubleshooting." },
  ];

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [shuffledImages, setShuffledImages] = useState([]);

  useEffect(() => {
    // Shuffle images once when the component mounts
    setShuffledImages(shuffleArray(images));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures this effect runs only once

  const shuffleArray = (array) => {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const handleClickOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };


  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <Card className="card" style={{ overflowY: 'auto', maxHeight: '80vh' }} sx={{backgroundColor: 'rgba(255, 255, 255, 0.95)'}}>
          <CardContent>
            <Typography variant="h4" component="h2">
              Tools & Technologies
            </Typography>
            <br />
            <Typography variant="h5" component="p">
              Below is a non-exhaustive grid of my tool and technology experience
            </Typography>
            <br />
            <Typography variant="body1" component="p">
              Click any image to learn more about my experience.
            </Typography>
            <br />
            <Grid container className="technology-grid" spacing={2}>
              {shuffledImages.map((image, index) => (
                <Grid item key={index} className="technology-grid-item" style={{ height: "150px" }}>
                  <img 
                    src={image.src} 
                    alt={image.title} 
                    style={{ width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer' }} 
                    onClick={() => handleClickOpen(image)}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Footer />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{selectedImage ? selectedImage.title : ''}</DialogTitle>
          <DialogContent>
            <DialogContentText>{selectedImage ? selectedImage.description : ''}</DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
      <NowPlaying/>
    </ThemeProvider>
  );
}

export default Technologies;
