import React, { useEffect, useState } from 'react';
import { HiOutlineStatusOffline } from 'react-icons/hi';
import { BiErrorCircle } from 'react-icons/bi';
import { Typography, Box, useMediaQuery } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import '../styles.css';

// URL for the deployed function
const NOW_PLAYING_ENDPOINT = 'https://nowplaying-rnr4tqbsua-uc.a.run.app';

export const getNowPlaying = async () => {
    try {
        const response = await fetch(NOW_PLAYING_ENDPOINT);

        if (response.status === 204) {
            return null; // No content means nothing is currently playing
        }

        if (response.status >= 400) {
            throw new Error('Unable to Fetch Song');
        }

        const song = await response.json();
        return song;
    } catch (error) {
        console.error('Error fetching currently playing song:', error);
        return 'error';
    }
};

const NowPlaying = () => {
    const [nowPlaying, setNowPlaying] = useState('loading'); // Set initial state to 'loading'
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        const fetchNowPlaying = async () => {
            const data = await getNowPlaying();
            setNowPlaying(data || 'offline'); // If data is null, set state to 'offline'
        };

        fetchNowPlaying();
        const intervalId = setInterval(fetchNowPlaying, 1000); // Fetch data every 1 second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    let playerState = '';
    let secondsPlayed = 0,
        minutesPlayed = 0,
        secondsTotal = 0,
        minutesTotal = 0;
    let albumImageUrl = '';
    let title = '';
    let artist = '';

    if (nowPlaying === 'loading') {
        title = '';
        artist = 'Connecting...';
    } else if (nowPlaying === 'offline') {
        playerState = 'OFFLINE';
        title = '';
        artist = "I'm not listening to anything right now";
    } else if (nowPlaying === 'error') {
        title = '';
        artist = 'Error fetching data';
    } else if (nowPlaying && nowPlaying.title) {
        playerState = nowPlaying.isPlaying ? 'PLAY' : 'PAUSE';

        secondsPlayed = Math.floor(nowPlaying.timePlayed / 1000);
        minutesPlayed = Math.floor(secondsPlayed / 60);
        secondsPlayed = secondsPlayed % 60;

        secondsTotal = Math.floor(nowPlaying.timeTotal / 1000);
        minutesTotal = Math.floor(secondsTotal / 60);
        secondsTotal = secondsTotal % 60;

        albumImageUrl = nowPlaying.albumImageUrl;
        title = nowPlaying.title;
        artist = nowPlaying.artist;
    }

    const pad = (n) => (n < 10 ? `0${n}` : n);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: isMobile ? '5px' : '5px',
                left: isMobile ? '50%' : '5px',
                transform: isMobile ? 'translateX(-50%)' : 'none',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                borderRadius: '4px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                minWidth: isMobile ? '90%' : '350px',
                maxWidth: isMobile ? '95%' : '350px',
                display: 'flex',
                alignItems: 'center',
                p: 1,
                zIndex: 1000,
                overflow: 'hidden', // Hide overflow
                flexWrap: 'wrap', // Allow content to wrap
            }}
        >
            {playerState !== 'OFFLINE' && playerState !== 'error' && nowPlaying !== 'loading' && (
                <Box sx={{ mr: 1 }}>
                    <img
                        src={albumImageUrl}
                        alt="Album"
                        style={{
                            width: '60px',
                            height: '60px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                        }}
                    />
                </Box>
            )}
            <Box sx={{ flex: 1, minWidth: 0 }}>
                <Typography
                    variant="body1"
                    component="div"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block', // Ensure text block formatting
                    }}
                >
                    {playerState === 'PLAY' || playerState === 'PAUSE' ? (
                        <a href={nowPlaying.songUrl} style={{ textDecoration: 'none', color: 'black' }}>
                            {title}
                        </a>
                    ) : (
                        title
                    )}
                </Typography>
                <Typography
                    variant="body2"
                    component="div"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block',
                    }}
                >
                    {playerState === 'PLAY' || playerState === 'PAUSE' ? (
                        <a href={nowPlaying.artistUrl} style={{ textDecoration: 'none', color: 'black' }}>
                            {artist}
                        </a>
                    ) : (
                        artist
                    )}
                </Typography>
                {playerState !== 'OFFLINE' && playerState !== 'error' && nowPlaying !== 'loading' && (
                    <Typography variant="caption" component="div">
                        {pad(minutesPlayed)}:{pad(secondsPlayed)} / {pad(minutesTotal)}:{pad(secondsTotal)}
                    </Typography>
                )}
            </Box>
            <Box sx={{ ml: 1, textAlign: 'center' }}>
                {playerState === 'PLAY' ? (
                    <PlayArrowIcon sx={{ fontSize: 40 }} title="Now Listening" />
                ) : playerState === 'PAUSE' ? (
                    <PauseIcon sx={{ fontSize: 40 }} title="Paused" />
                ) : playerState === 'OFFLINE' ? (
                    <HiOutlineStatusOffline size={40} />
                ) : (
                    <BiErrorCircle size={40} />
                )}
            </Box>
        </Box>
    );
};

export default NowPlaying;
