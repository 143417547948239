import React, { useState, useEffect } from "react";
import axios from "axios";
import { marked } from 'marked'; // Import the marked library
import { Card, CardContent, Typography, createTheme, ThemeProvider } from '@mui/material';
import Footer from './Footer';
import NowPlaying from "../components/NowPlaying";

const Home = () => {
  const [portfolioCard, setPortfolioCard] = useState(null);

  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  useEffect(() => {
    const fetchPortfolioCard = async () => {
      try {
        const response = await axios.get('https://strapi-app-665134228599.us-central1.run.app/api/portfolio-cards/3?populate=*', {
          headers: {
            'Authorization': "Bearer 9fbc07b4949b91d5064e6bad40161ef91712b5e9afad699b0fa6acfe62d2dd72baac03de86965e68f77eb8d04f70ca38f3feca973a8fc62c7bd332b59c2bbd0fdbdfcb7e933ea18797bdfc484574fd32a54a2763c6f22faba3bb580c9ffc8862d7f26c2737186665bf50a9d2d1c10434cde408927cff6f0ee7dc141335129dae",
          },
        });
        setPortfolioCard(response.data.data);
      } catch (error) {
        console.error("Error fetching portfolio card:", error);
      }
    };

    fetchPortfolioCard();
  }, []);

  // Function to get the original image URL
  const getImageUrl = (imageData) => {
    return imageData ? imageData.attributes.url : '';
  };

  // Determine image size based on screen width
  const getImageStyle = () => {
    if (window.innerWidth < 600) { // Small screens
      return {
        width: '150px',
        height: '150px',
      };
    } else if (window.innerWidth < 1200) { // Medium screens
      return {
        width: '200px',
        height: '200px',
      };
    } else { // Large screens
      return {
        width: '400px',
        height: '400px',
      };
    }
  };

  // Initial image style
  const [imageStyle, setImageStyle] = useState(getImageStyle());

  useEffect(() => {
    // Update image size on window resize
    const handleResize = () => setImageStyle(getImageStyle());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        {portfolioCard ? (
          <Card className="card" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.95)', position: 'relative' }}>
            <CardContent className="card-content">
              <Typography variant="h4" component="h2">
                {portfolioCard.attributes.Title}
              </Typography>
              <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: marked(portfolioCard.attributes.RichText) }} />
              
              {/* Display the original image */}
              {portfolioCard.attributes.Images.data.length > 0 && (
                <div style={{ position: 'absolute', bottom: 0, left: '50%', transform: 'translateX(-50%)', marginBottom: '10px' }}>
                  <img
                    src={getImageUrl(portfolioCard.attributes.Images.data[0])}
                    alt={portfolioCard.attributes.Images.data[0].attributes.alternativeText || 'Image'}
                    style={{
                      ...imageStyle,
                      borderRadius: '50%', // Make the image circular
                      objectFit: 'cover',  // Ensure the image covers the area
                      marginTop: '100px' 
                    }}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        ) : (
          <Typography variant="h6" component="p">
            Loading...
          </Typography>
        )}
        <Footer />
      </div>
      <NowPlaying/>
    </ThemeProvider>
  );
}

export default Home;
