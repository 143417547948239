import {React, useEffect} from 'react';
import {Routes, Route, useLocation} from "react-router-dom";
import Home from "./pages/Home";
import Experience from "./pages/Experience";
import Blog from "./pages/Blog";
import Projects from "./pages/Projects";
import Technologies from "./pages/Technologies";
import Contact from "./pages/Contact";
import Navbar from './pages/Navbar';
import NoPage from './pages/NoPage'
//import MouseFollower from './pages/MouseFollower';
//import TagManager from 'react-gtm-module'

/*const tagManagerArgs = {
  gtmId: 'GTM-K3SRW3VP'
}
TagManager.initialize(tagManagerArgs)*/

const routeToTitleMap = {
  '/': 'Home',
  '/blog': 'Blog',
  '/contact': 'Contact',
  '/experience': 'Experience',
  '/technologies': 'Technologies',
  '/projects': 'Projects'
  // Add more routes and titles as needed
};

// Import Material-UI components
//import { CssBaseline } from '@mui/material'; // Example import, you can add more as needed

function App() {
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const pageTitle = routeToTitleMap[pathname] || 'Default Title';

    // Fire page_view event only on initial load
    if (!window.dataLayer.some(event => event.event === 'pageView')) {
      window.dataLayer.push({
        event: 'pageView',
        pageData: {
          url: "www.jacob-montgomery.com" + pathname,
          title: pageTitle
        }
      });
    }
  }, [location]);
  return (
   <>
    <Navbar/>
      <div>
    <Routes>        
      <Route path="/"  element={<Home/>} />
      <Route path="/experience"  element={<Experience />}/>
      <Route path="/technologies"  element={<Technologies/>}/>
      <Route path="/projects"  element={<Projects/>}/>
      <Route path="/blog"  element={<Blog/>}/>
      <Route path="/contact"  element={<Contact/>}/>
      <Route path="*"  element={<NoPage/>}/>
    </Routes>
    </div>
    </>
   
  );
}

export default App;
