import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios
import { marked } from 'marked'; // Import the marked library
import { Card, CardContent, Typography, createTheme, ThemeProvider } from '@mui/material'; // Import Material-UI components
import '../homeStyles.css'; // Import CSS file
import Footer from './Footer'; // Import Footer component

const Experience = () => {
  const [experienceCard, setExperienceCard] = useState(null); // State to hold the experience card

  // Create a custom theme with Roboto font for all typography
  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif', // Set Roboto as the font family
    },
  });

  useEffect(() => {
    // Fetch the experience card from Strapi
    const fetchExperienceCard = async () => {
      try {
        const response = await axios.get('https://strapi-app-665134228599.us-central1.run.app/api/portfolio-cards/4?populate=*', {
          headers: {
            'Authorization': "Bearer 9fbc07b4949b91d5064e6bad40161ef91712b5e9afad699b0fa6acfe62d2dd72baac03de86965e68f77eb8d04f70ca38f3feca973a8fc62c7bd332b59c2bbd0fdbdfcb7e933ea18797bdfc484574fd32a54a2763c6f22faba3bb580c9ffc8862d7f26c2737186665bf50a9d2d1c10434cde408927cff6f0ee7dc141335129dae", // Using the provided API token
          },
        });
        setExperienceCard(response.data.data); // Assuming the response structure
      } catch (error) {
        console.error("Error fetching experience card:", error);
      }
    };

    fetchExperienceCard();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="container"> {/* Apply container class */}
        {experienceCard ? (
          <Card className="card" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
            <CardContent className="card-content">
              <Typography variant="h4" component="h2">
                {experienceCard.attributes.Title}
              </Typography>
              <br />
              <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: marked(experienceCard.attributes.RichText) }} /> {/* Use Markdown for RichText */}
            </CardContent>
          </Card>
        ) : (
          <Typography variant="h6" component="p">
            Loading...
          </Typography>
        )}
        <Footer /> {/* Add Footer component */}
      </div>
    </ThemeProvider>
  );
}

export default Experience;
