import React from "react";
import { Card, CardContent, Typography, createTheme, ThemeProvider, Link } from '@mui/material'; // Import Material-UI components
import '../homeStyles.css'; // Import CSS file
import Footer from './Footer'; // Import Footer component
import NowPlaying from "../components/NowPlaying";

const Contact = () => {
  // Create a custom theme with Roboto font for all typography
  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif', // Set Roboto as the font family
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="container"> {/* Apply container class */}
        <Card className="card" sx={{backgroundColor: 'rgba(255, 255, 255, 0.95)'}}>
          <CardContent>
            <Typography variant="h4" component="h2">
              Want to contact me?
            </Typography>
            <br />
            <Typography variant="h5" component="p">
              Details below
            </Typography>
            <br />
            <Typography variant="body1" component="p"> {/* Change variant to body1 */}
            <Link href="https://www.linkedin.com/in/jacob-montgomery1/" target="_blank" rel="noopener">LinkedIn</Link>, or email me at <Link href="mailto:jacobmontgomery@hotmail.co.uk">jacobmontgomery@hotmail.co.uk</Link>
            </Typography>
          </CardContent>
        </Card>
        <Footer /> {/* Add Footer component */}
      </div>
      <NowPlaying/>
    </ThemeProvider>
  );
}

export default Contact;
