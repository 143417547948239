import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, List, ListItemButton, ListItemText, Drawer, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

const Navbar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <>
            <AppBar position="fixed" color="primary" style={{ backgroundImage: "linear-gradient(to left, #00395d, #8f8f8c)" }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="div" style={{ textDecoration: 'none', color: 'inherit', padding: '8px' }}>
                        Jacob Montgomery
                    </Typography>
                    {isMobile && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {!isMobile && (
                        <List sx={{ display: 'flex' }}>
                            <ListItemButton component="div" to="/" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                            <ListItemButton component="div" to="/experience" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                                <ListItemText primary="Experience" />
                            </ListItemButton>
                            <ListItemButton component="div" to="/technologies" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                                <ListItemText primary="Technologies" />
                            </ListItemButton>
                            <ListItemButton component="div" to="/projects" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                                <ListItemText primary="Projects" />
                            </ListItemButton>
                            <ListItemButton component="div" to="/blog" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                                <ListItemText primary="Blog" />
                            </ListItemButton>
                            <ListItemButton component="div" to="/contact" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                                <ListItemText primary="Contact" />
                            </ListItemButton>
                        </List>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer}
            >
                <List>
                    <ListItemButton component="div" to="/" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                    <ListItemButton component="div" to="/experience" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                        <ListItemText primary="Experience" />
                    </ListItemButton>
                    <ListItemButton component="div" to="/technologies" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                        <ListItemText primary="Technologies" />
                    </ListItemButton>
                    <ListItemButton component="div" to="/projects" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                        <ListItemText primary="Projects" />
                    </ListItemButton>
                    <ListItemButton component="div" to="/blog" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                        <ListItemText primary="Blog" />
                    </ListItemButton>
                    <ListItemButton component="div" to="/contact" sx={{ padding: '8px', textDecoration: 'none', color: 'inherit' }}>
                        <ListItemText primary="Contact" />
                    </ListItemButton>
                </List>
            </Drawer>
        </>
    );
}

export default Navbar;
