import React from 'react';
import { AppBar, Toolbar, Typography, useMediaQuery } from '@mui/material'; 

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen width is less than 600px (mobile)

    if (isMobile) {
        return null; // Return nothing to hide the Footer on mobile
    }

    return (
        <AppBar position="fixed" color="primary" style={{ top: 'auto', bottom: 0, width: '30%', right: 0, backgroundImage: "linear-gradient(to left, #00395d, #8f8f8c)" }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
                <Typography variant="body1" component="div" style={{ textDecoration: 'none', color: 'inherit', padding: '4px', lineHeight: '1', margin: 'auto' }}>
                    This site is made using React, Strapi, Tembo and Firebase, styled with Material UI.
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Footer;
