import React, { useState, useEffect } from "react";
import axios from "axios";
import { marked } from 'marked'; // Import the marked library
import { Card, CardContent, Typography, createTheme, ThemeProvider, Grid } from '@mui/material'; // Import Material-UI components
import '../homeStyles.css'; // Import CSS file
import Footer from './Footer';
import NowPlaying from "../components/NowPlaying";

const Projects = () => {
  const [projectCard, setProjectCard] = useState(null); // State to hold the project card
  const [isSecondCard, setIsSecondCard] = useState(false); // State to toggle between views

  const theme = createTheme({
    typography: {
      fontFamily: 'Roboto, sans-serif',
    },
  });

  useEffect(() => {
    const fetchProjectCard = async () => {
      try {
        const response = await axios.get('https://strapi-app-665134228599.us-central1.run.app/api/portfolio-cards/5?populate=*', {
          headers: {
            'Authorization': "Bearer 9fbc07b4949b91d5064e6bad40161ef91712b5e9afad699b0fa6acfe62d2dd72baac03de86965e68f77eb8d04f70ca38f3feca973a8fc62c7bd332b59c2bbd0fdbdfcb7e933ea18797bdfc484574fd32a54a2763c6f22faba3bb580c9ffc8862d7f26c2737186665bf50a9d2d1c10434cde408927cff6f0ee7dc141335129dae",
          },
        });
        setProjectCard(response.data.data); // Assuming the response structure
      } catch (error) {
        console.error("Error fetching project card:", error);
      }
    };

    fetchProjectCard();
  }, []);

  // Function to get the original image URL
  const getImageUrl = (imageData) => {
    return imageData ? imageData.attributes.url : '';
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        {projectCard ? (
          <Card className="card" sx={{ backgroundColor: 'rgba(255, 255, 255, 0.95)' }}>
            <CardContent className="card-content">
              <Typography variant="h4" component="h2">
                {projectCard.attributes.Title}
              </Typography>
              <br />
              <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: marked(projectCard.attributes.RichText) }} />
              
              {/* Display the original image */}
              {projectCard.attributes.Images.data.length > 0 && (
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={8} md={6} lg={6}>
                    <img
                      src={getImageUrl(projectCard.attributes.Images.data[0])}
                      alt={projectCard.attributes.Images.data[0].attributes.alternativeText || 'Image'}
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        ) : (
          <Typography variant="h6" component="p">
            Loading...
          </Typography>
        )}
        {/* 
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => setIsSecondCard(!isSecondCard)} 
          sx={{ marginTop: '20px' }}
        >
          {isSecondCard ? 'Back' : 'Show More'}
        </Button>
        */}
        <Footer />
      </div>
      <NowPlaying/>
    </ThemeProvider>
  );
}

export default Projects;
